<template>
  <div style="min-height: calc(100vh - 135px)">
    <div class="colorbg">
      <!-- <div class="left">
        <div class="li" @click="getShoucang()">
          <img src="../assets/goods.png" alt="" />
          <span>{{ query.collectStatus == 1 ? "查看全部" : "收藏商品" }}</span>
        </div>
      </div> -->
      <div class="right">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><span @click="changeAll()" style="font-weight: bold">全部</span></el-breadcrumb-item>
          <el-breadcrumb-item v-if="firstName">{{
            firstName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="twoNmae">{{ twoNmae }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="flex">
          <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" size="small" style="width: 200px"
            v-model="query.keyword" @change="getGoodeList">
          </el-input>

          <div class="paixu">
            <div class="li">
              <el-button type="primary" :plain="query.sortField == 'goodName' ? false : true" size="small"
                icon="el-icon-s-promotion" @click="changeSort('goodName')">名称
                <i class="el-icon-top el-icon--right" v-if="
                  query.sortOrder == 'asc' && query.sortField == 'goodName'
                "></i>
                <i class="el-icon-bottom el-icon--right" v-if="
                  query.sortOrder == 'desc' && query.sortField == 'goodName'
                "></i>
              </el-button>
            </div>
            <div class="li">
              <el-button type="primary" :plain="query.sortField == 'sellNum' ? false : true" size="small"
                @click="changeSort('sellNum')" icon="el-icon-s-flag">销量
                <i class="el-icon-top el-icon--right" v-if="
                  query.sortOrder == 'asc' && query.sortField == 'sellNum'
                "></i>
                <i class="el-icon-bottom el-icon--right" v-if="
                  query.sortOrder == 'desc' && query.sortField == 'sellNum'
                "></i>
              </el-button>
            </div>
            <div class="li">
              <el-button type="primary" :plain="query.sortField == 'collectNum' ? false : true" size="small"
                @click="changeSort('collectNum')" icon="el-icon-s-custom">收藏
                <i class="el-icon-top el-icon--right" v-if="
                  query.sortOrder == 'asc' && query.sortField == 'collectNum'
                "></i>
                <i class="el-icon-bottom el-icon--right" v-if="
                  query.sortOrder == 'desc' && query.sortField == 'collectNum'
                "></i>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="goodslist">
      <div class="fenlei">
        <div class="hasNone" v-if="leftList.length == 0">暂无分类</div>
        <div>
          <div v-for="(item, index) in leftList" :key="index">
            <el-popover placement="right" width="300" trigger="hover"
              :popper-class="item.showchildren ? 'modal_popper' : 'hidePopper'">
              <div class="erji" v-if="item.children.length > 0">
                <div class="li" v-for="(item2, index2) in item.children" :key="index2" @click="changeType2(item, item2)">
                  <div style="display: flex;align-items: center;">
                    <img :src="item2.icon" alt="" style="width:30px;margin-right: 5px;"> {{ item2.name }}
                  </div>
                </div>
              </div>
              <div class="list" slot="reference" style="width: 100%" @click="changeType(item)">
                <div class="flex">
                  <img :src="item.icon" alt="" />
                  <span>{{ item.name }}</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>

      <div class="goods" :class="list.length == 0 ? 'goodsNone' : ''">
        <el-empty description="暂无数据" v-if="list.length == 0"></el-empty>
        <div class="lists" v-for="(item, index) in list" :key="index" @click="toInfo(item)">
          <div class="list">
            <div class="flex">
              <div class="left">
                <img :src="item.goodPic[0]" alt="" />
              </div>
              <div class="right">
                <div class="name">{{ item.goodName }}</div>
                <div class="desc">
                  <span>收藏数：{{ item.collectNum }}</span>
                  <div class="shocuangIcon" @click.stop="shoucang(item.id, item.collectStatus)">
                    收藏<img src="@/assets/shoucang.png" alt="" v-if="item.collectStatus == 0" />
                    <img src="@/assets/shoucang_a.png" alt="" v-if="item.collectStatus == 1" />
                  </div>
                </div>
                <div class="desc" style="margin-bottom: 2px">
                  <span v-if="item.unitDetails[item.checkInv]">库存：{{ item.unitDetails[item.checkInv].stock }}</span>
                  <span v-if="item.unitDetails[item.checkInv]">折扣：{{currency.symbol}}{{ item.unitDetails[item.checkInv].discount }}</span>
                </div>

                <div class="desc guige">
                  <span v-for="(item2, index2) in item.unitDetails" :key="index2">
                    <el-tag :type="index2 == item.checkInv ? 'error' : 'info'" @click.stop="checkguige(index, index2)">{{
                      item2.unitName }} | {{ item2.specs }}</el-tag>
                    <div>
                      <el-tag @click.stop="checkguige2(index,index2, index3)" :type="index3 == item2.checkInv && index2 == item.checkInv ? 'error' : 'info'" v-for="(item3, index3) in item2.propertys" :key="index3">{{ item3.proValue }}</el-tag>
                    </div>
                  </span>


                </div>
                
                <div class="desc" v-if="item.unitDetails.length > 0">
                  <span style="color: red;font-size: 18px;font-weight: bold;">售价：{{currency.symbol}}{{
                    item.unitDetails[item.checkInv].sellPrice
                  }}</span>
                </div>


                <!-- <div class="zhekou">
                  <span>折扣：$0.12</span>
                </div> -->

                <div class="addCar" v-if="item.unitDetails.length > 0">
                  <span @click.stop>
                    <el-input-number size="mini" v-model="item.num" @change="handleChange" :min="1"
                      :max="item.unitDetails[item.checkInv].stock" label="描述文字"></el-input-number>
                  </span>
                  <img src="../assets/shopCar.png" alt="" @click.stop="addCar(item)" />
                </div>
                <div v-else style="color: red;font-size: 18px;font-weight: bold;">已售完</div>
              </div>
            </div>
          </div>
        </div>
        <div class="fenye">
          <el-pagination background layout="prev, pager, next" @current-change="currentChange" :page-size="query.pageSize"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  goodClassifylist,
  goodlist,
  goodcollect,
  goodunCollect,
  cartadd,
  onlineget
} from "@/request/api.js";

export default {
  name: "goodslist",
  data() {
    return {
      currency:{},
      num: 1,
      total: 0,
      searchType: 1,
      list: [],
      input2: "",
      leftList: [],
      query: {
        pageSize: 12,
        pageNum: 1,
        classifyId: "",
        keyword: "",
        sortField: "goodName",
        sortOrder: "asc",
        collectStatus: "",
      },
      firstName: "",
      twoNmae: "",
    };
  },

  watch: {
    "$route.query.collectStatus": {
      handler(val) {
        if (val) {
          this.query.collectStatus = 1;
          this.getGoodeList();
        } else {
          this.query.collectStatus = "";
          this.getGoodeList();
        }
      },
      deep: true,
    },
  },
  created() {
    this.getnavigation();
    if (this.$route.query.collectStatus) {
      this.query.collectStatus = 1;
      this.getGoodeList();
    } else {
      this.query.collectStatus = "";
      this.getGoodeList();
    }
    onlineget().then(res=>{
      this.currency = res.data.currency
    })
  },
  methods: {
    currentChange(e) {
      this.query.pageNum = e;
      this.getGoodeList();
    },
    checkguige(index, index2) {
      console.log(index);
      console.log(index2);
      this.list[index].num = 1;
      this.list[index].checkInv = index2;
      this.list[index].unitDetails[index2].checkInv = 0;
    },
    checkguige2(index,index2, index3) {
      this.list[index].checkInv = index2;
      this.list[index].unitDetails[index2].checkInv = index3;
      this.$forceUpdate()
    },
    toGooleMap() {
      let form = this.$store.state.place;
      let lat = 39.916527;
      let lng = 116.397128;
      window.open(`http://www.google.com/maps/dir/${form}/${lat},${lng}`);
    },
    getnavigation() {
      goodClassifylist().then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            if (item.children.length > 0) {
              item.showchildren = true;
            } else {
              item.showchildren = false;
            }
          });
        }
        this.leftList = res.data;
      });
    },
    changeAll() {
      this.query.classifyId = "";
      this.getGoodeList();
    },
    //获取商品列表
    getGoodeList() {
      goodlist(this.query).then((res) => {
        console.log(res);

        if (res.rows.length > 0) {
          res.rows.forEach((item) => {
            if (item.unitDetails.length > 0) {
              item.checkInv = 0;
              item.unitDetails.forEach((item2)=>{
                   item2.checkInv = 0;
                })
            }
            
            if (item.goodPic) {
              item.goodPic = item.goodPic.split(",");
            }
            item.num = 1;
          });
        }
        this.list = res.rows;
        console.log(this.list);

        this.total = res.total;
      });
    },
    toInfo(item) {
      this.$router.push({
        path: `/goodsInfo?id=${item.id}`,
      });
    },

    getShoucang() {
      if (this.query.collectStatus == 1) {
        this.query.collectStatus = "";
      } else {
        this.query.collectStatus = 1;
      }
      this.getGoodeList();
    },
    handleChange(e) {
      console.log(e);
    },
    changeType(e) {
      console.log(e);
      this.query.classifyId = e.id;
      this.firstName = e.name;
      this.twoNmae = "";
      this.getGoodeList();
    },
    changeType2(item, item2) {
      this.query.classifyId = item.id;
      this.firstName = item.name;
      this.twoNmae = item2.name;
      this.getGoodeList();
    },
    changeSort(e) {
      if (this.query.sortField == e) {
        if (this.query.sortOrder == "asc") {
          this.query.sortOrder = "desc";
        } else {
          this.query.sortOrder = "asc";
        }
      } else {
        this.query.sortField = e;
      }

      this.getGoodeList();
    },
    addCar(item) {
      console.log(item);
      let unitDetailId = item.unitDetails[item.checkInv].unitDetailId;
      let gpId = '';

      if(item.unitDetails[item.checkInv].propertys && item.unitDetails[item.checkInv].propertys.length>0){
        gpId = item.unitDetails[item.checkInv].propertys[item.unitDetails[item.checkInv].checkInv].gpId
      }
      let data = {
        goodNum: item.num,
        goodId: item.id,
        gpId:gpId,
        unitDetailId,
        priceSystemId: item.goodPriceSystemMember.priceSystemId,
      };
      cartadd(data).then((res) => {
        console.log(res);
        this.$emit('addCarSuccess')
        this.$notify({
          title: "成功",
          message: "添加成功",
          type: "success",
        });
      });
    },
    shoucang(id, type) {
      if (type == 0) {
        //收藏
        goodcollect({ goodId: id }).then((res) => {
          this.$message.success("收藏成功");
          this.getnavigation();
          this.getGoodeList();
        });
      } else {
        //取消收藏
        goodunCollect({ goodId: id }).then((res) => {
          this.$message.success("取消成功");

          this.getnavigation();

          this.getGoodeList();
        });
      }
    },
    sizeChange(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.hasNone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 15px;
  color: #787777;
}

.fenye {
  position: absolute;
  bottom: -40px;
}

.guige {
  justify-content: flex-start !important;
  flex-wrap: wrap;

  .el-tag {
    margin-right: 4px;
    margin-top: 3px;
  }
}

.colorbg {
  width: 70%;
  margin: 0 auto;
  height: 60px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;
    width: 180px;

    .li {
      display: flex;
      align-items: center;
      margin-left: 40px;
      cursor: pointer;

      img {
        width: 25px;
        margin-right: 10px;
      }
    }

    .title {
      margin-right: 20px;
    }

    img {
      width: 35px;
      margin-right: 10px;
    }
  }

  .right {
    display: flex;
    align-items: center;
    width: 180px;
    width: 100%;
    margin-left: 20px;
    justify-content: space-between;
    cursor: pointer;

    .flex {
      display: flex;
      align-items: center;
    }
  }

  .paixu {
    display: flex;
    align-items: center;

    .li {
      display: flex;
      align-items: center;
      margin-left: 20px;
      font-size: 15px;
      cursor: pointer;
      color: #757474;

      img {
        width: 18px;
        margin-left: 4px;
      }
    }
  }
}

.erji {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;

  .li {
    width: 50%;
    margin-bottom: 10px;
  }
}

.addCar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;

  img {
    width: 30px;
  }
}

.goodslist {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 65px;
  display: flex;
  justify-content: space-between;

  .fenlei {
    width: 180px;
    border: 1px solid #e1e1e1;

    .list {
      height: 50px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: space-between;
      padding: 0 10px;
      font-size: 15px;
      border-bottom: 1px solid #e1e1e1;
      position: relative;
      box-sizing: border-box;

      i {
        font-weight: bold;
        position: absolute;
        right: 10px;
      }

      .flex {
        display: flex;
        align-items: center;
      }

      img {
        width: 20px;
        margin-right: 5px;
      }
    }
  }

  .goods {
    width: 90%;
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }

  .goodsNone {
    justify-content: center;
    min-height: 500px;
  }

  .lists {
    margin-bottom: 10px;
    width: 33%;
    display: inline-block;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
    background: #f5f5f5;

    .list {
      width: 100%;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;

      .flex {
        display: flex;
      }

      .mobile {
        display: flex;
        justify-content: flex-end;
        height: 50px;
        align-items: center;
        border-top: 1px dashed #ccc;

        .lus {
          display: flex;
          align-items: center;
          margin-left: 60px;
          color: #5e5e5e;

          img {
            width: 25px;
            margin-right: 10px;
          }
        }
      }

      .left {
        img {
          width: 160px;
          margin-right: 10px;
          border-radius: 10px;
          height: 188px;
        }
      }

      .right {
        width: 100%;

        .name {
          font-size: 20px;
          margin-bottom: 10px;
          color: #a80404;
          font-weight: bold;
        }

        .zhekou {
          font-size: 14px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          color: #5e5e5e;
        }

        .desc {
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #5e5e5e;
          font-size: 14px;

          .shocuangIcon {
            display: flex;
            align-items: center;

            img {
              width: 16px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .goods::after {
    content: "";
    width: 33%;
  }

  .goodsNone::after {
    content: "";
    width: 0;
  }
}
</style>
