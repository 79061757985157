<template>
  <div class="headertop">
    <div class="center">
      <div class="left">
        <img :src="storeData.logo" alt="" @click="toPage('/home')" />
        <span class="title" @click="toPage('/home')">{{ storeData.name }}</span>
      </div>
      <div class="right">
        <div class="li" :class="Inv == 1 ? 'active' : ''" style="margin-left: 0" @click="toPage('/home', 1)">
          <i class="iconfont">&#xe62e;</i>
          <!-- <img src="../assets/home.png" alt="" style="width: 25px" /> -->
          <span>首页</span>
        </div>
        <div class="li" :class="Inv == 2 ? 'active' : ''" @click="toPage('/car', 2)">
          <i class="iconfont">&#xe673;</i>
          <span>购物车</span>
          <div class="numbox" v-if="carPrice.itemCount>0">
            {{ carPrice.itemCount }} | ${{ carPrice.allPrice }}
          </div>
        </div>

        <el-popover placement="bottom" trigger="hover">
          <div class="kefuDia" v-for="(item, index) in list" :key="index">
            
            <div class="text flexbox">
              <div class="top">
              <img :src="item.photo" alt="" />
            </div>
            <div>
              <div>编号：{{ item.mainCode }}</div>
              <div>姓名：{{ item.name }}</div>
            </div>
            </div>
            <!-- <img class="wechatImg" :src="item.wxPhoto" alt="" /> -->
            <div class="text" style="margin-top: 10px;">
              <div>微信：{{ item.wx }}</div>
              <div>手机：{{ item.phone }}</div>
            </div>
          </div>
          <div class="li" :class="Inv == 3 ? 'active' : ''" @click="Inv = '3'" slot="reference">
            <i class="iconfont">&#xe643;</i>
            <span>客服</span>
          </div>
        </el-popover>
        <div class="li" :class="Inv == 4 ? 'active' : ''" @click="getShoucang(4)">
          <i class="iconfont">&#xe612;</i>
          <span>我的收藏</span>
        </div>
        <div class="li" :class="Inv == 5 ? 'active' : ''" @click="toPage('/my', 5)">
          <i class="iconfont">&#xe626;</i>
          <span>个人中心</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { customerServiceget, onlineget,cartgetSubscript } from "@/request/api.js";

export default {
  name: "headertop",
  props: {
    Inv: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      input2: "",
      list: [],
      carPrice:{},
      storeData: "",
    };
  },
  created() {
    this.getData();
    this.getPrice()
  },
  methods: {
    getShoucang(index) {
      // if (this.query.collectStatus == 1) {
      //   this.query.collectStatus = "";
      // } else {
      //   this.query.collectStatus = 1;
      // }
      // this.getGoodeList();
      this.$router.push({
        path: `/collect?collectStatus=1`,
      });
    },
    getPrice(){
      cartgetSubscript().then((res) => {
        console.log('购物车信息')
        console.log(res)
        this.carPrice = res.data
      
      });
    },
    getData() {
      customerServiceget().then((res) => {
        this.list = res.data;
      });

      onlineget().then((res) => {
        this.storeData = res.data;
        this.$store.commit("setStoreName", this.storeData.name);
        this.$store.commit("setLogi", this.storeData.logo);
      });
    },
    toPage(url, index) {
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.headertop {
  height: 70px;
  background: #1d1c1c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 100;

}

.kefuDia {
  display: inline-block;
  margin-right: 10px;
  position: relative;

  .wechatImg {
    width: 100px;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
  }

  .text {
    
    div {
      font-size: 15px;
      text-align: left;
    }
  }

  .flexbox{
    display: flex;
    align-items: center;
  }

  .top {
    margin-right: 10px;

    img {
      height: 40px;
      width: auto;
      border-radius: 10px;
      display: block;
      margin: 0 auto;

    }

    div {
      margin-bottom: 4px;
    }
  }

  .num {
    margin-top: 5px;
  }
}

.li {
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 40px;
  cursor: pointer;

}

img {
  width: 25px;
  margin-right: 10px;
}

.active {
  color: #ff6464 !important;
}

.center {
  width: 70%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right {
    display: flex;
    align-items: center;

    .li {
      display: flex;
      align-items: center;
      color: #fff;
      margin-left: 50px;
      cursor: pointer;
      position: relative;

      .numbox {
        font-size: 12px;
        position: absolute;
        right: -34px;
        top: -16px;
        background: #ee1c1c;
        border-radius: 10px;
        color: #fff;
        padding: 4px 10px;
      }

    }

    .iconfont {
      font-size: 24px;
      margin-right: 5px;
    }
  }

  .left {
    display: flex;
    align-items: center;
    color: #fff;
    width: 40%;

    .title {
      font-weight: bold;
      display: block;
      margin-right: 40px;
      white-space: nowrap;
    }

    img {
      height: 47px;
      width: auto;
      margin-right: 15px;
    }
  }
}</style>
